/**
 * IndigoLabs.Suite.Identity.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@indigo.si
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TokenUsage = 0 | 1;

export const TokenUsage = {
    NUMBER_0: 0 as TokenUsage,
    NUMBER_1: 1 as TokenUsage
};

